
import Vue, { PropType, VueConstructor } from "vue";

export default Vue.extend({
  name: "FilterBar",
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Boolean,
    },
    component: {
      type: Function as PropType<VueConstructor>,
      required: true,
    },
  },
  data: () => ({
    internalFilters: {},
  }),
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    filters: {
      handler(val) {
        this.internalFilters = val;
      },
      deep: true,
      immediate: true,
    },
    internalFilters: {
      handler() {
        this.$emit("update:filters", this.internalFilters);
      },
      deep: true,
    },
  },
});
