export const getOnlySetQueryParameters = (
  params: Record<string, any>
): Record<string, any> =>
  Object.fromEntries(
    Object.entries(params || {}).filter(
      ([_, v]) =>
        v !== null &&
        v !== "" &&
        v !== undefined &&
        !(Array.isArray(v) && v.length === 0)
    )
  );
