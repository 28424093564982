
import Vue, { PropType } from "vue";
import { CrudTableHeader } from "@/modules/crudTable/components/KCrudTable.vue";
import dayjs from "@/plugins/dayjs";

export default Vue.extend({
  name: "DateColumn",
  props: {
    item: {
      type: Object,
      required: true,
    },
    header: {
      type: Object as PropType<CrudTableHeader>,
      required: true,
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      required: false,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    formattedValue() {
      if (!this.value) return "";

      return dayjs(this.value).format(
        this.header.typeParameters?.dateType || "LL"
      );
    },
  },
});
